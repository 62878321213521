<template>
  <v-container style="max-width: 600px" class="pa-0">
    <template>
      <v-card :loading="loading" max-width="600">
        <template slot="progress">
          <v-progress-linear color="deep-orange" height="10" indeterminate></v-progress-linear>
        </template>
        <!-- <v-img height="250" :src="spot && spot.guide_images[0]"></v-img> -->
        <div v-if="spot">
          <template>
            <v-carousel v-model="model" height="250" hide-delimiters>
              <v-carousel-item v-for="img in spot.guide_images" :key="img">
                <v-img height="250" :src="img"></v-img>
              </v-carousel-item>
            </v-carousel>
          </template>

          <v-card-title>{{ spot.spot_name }}</v-card-title>

          <v-card-text>
            <!-- <v-row align="center" class="mx-0">
            <v-rating
              :value="4.5"
              color="amber"
              dense
              half-increments
              readonly
              size="14"
            ></v-rating>

            <div class="grey--text ms-4">4.5 (413)</div>
          </v-row> -->

            <div class="text-subtitle-1 font-weight-bold">{{ spot.guide_text }}</div>

            <div>
              <!-- <div>店舗電話: {{ spot.tel }}</div> -->
              <div>営業時間: {{ spot.open_hours }}</div>
              <div>店舗住所: {{ spot.address }}</div>
              <!-- <div>担当者: {{ spot.name }}</div>
              <div>担当者電話: {{ spot.phone }}</div> -->
            </div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <!-- <v-card-title>Schedule</v-card-title>
          <v-card-text>
            <v-timeline align-top dense>
              <v-timeline-item
                v-for="[key, value] in schedule"
                :key="key"
                small
                :color="colors[key]"
              >
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>{{ key }}</strong>
                  </v-col>
                  <v-col>
                    <div class="text-caption">{{ value }}</div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card-text> -->

          <v-card-actions>
            <v-btn large color="deep-orange darken-2" text @click="staff_chat()">問い合わせ</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </template>
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';
  import { contactURL } from '@/utils/const';
  export default {
    name: 'SportById',

    data() {
      return {
        model: 0,
        spot: null,
        schedule: [],
        colors: {
          mon: 'indigo lighten-3',
          tue: 'green lighten-3',
          wed: 'pink lighten-3',
          thu: 'teal lighten-3',
          fri: 'deep-purple lighten-3',
          sat: 'red lighten-3',
          sun: 'cyan lighten-3',
        },
        loading: false,
      };
    },

    mounted() {
      this.fetchSportById();
    },

    methods: {
      staff_chat: function() {
        window.location.href = contactURL;
      },
      async fetchSportById() {
        try {
          this.loading = true;
          const response = await Methods.getSpot(this.$route.params.spot_id);
          this.spot = response.data;
          console.log(this.spot);
          this.schedule = Object.entries(this.spot.default_schedule);
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
